import React, { useEffect, useState } from "react";
import ParticipantTable from "./table";
import { Client, Databases } from "appwrite";
import { useDispatch, useSelector } from "react-redux";
import { addleaderboard } from "../redux/Slice/user";


const Leaderboard = () => {
    const [showAllParticipants, setShowAllParticipants] = useState(false);
    const dispatch = useDispatch();

    const participants = useSelector((state) => state.userState.leaderboard);

    useEffect(() => {
        async function fetchData() {
            const client = new Client();

            try {
                if (client) {
                    client
                        .setEndpoint("https://cloud.appwrite.io/v1")
                        .setProject("657728939a212e1ff668");
                } else {
                    console.error('Error: Appwrite client is not defined.');
                }

                const databases = new Databases(client);

                const response = await databases.listDocuments(
                    "657731ac6ecfadf05ab3",
                    "65774aa9aa9b77184979"
                );
                dispatch(addleaderboard(response.documents));
            } catch (error) {
                console.error('Error during data fetch:', error); // Failure
            }
        }

        fetchData();
    }, []);

    const sortedArray = [...participants].sort((a, b) => a.Rank - b.Rank);

    const filteredArray = sortedArray.filter(item => item.Rank <= 3);

    const top5Participants = sortedArray.filter(item => item.Rank <= 5);

    const top15Participants = sortedArray.filter(item => item.Rank <= 15);

    return (
        <div className="pb-[10px]">
            <div className="flex">
            <img src="Group 1.png" className="w-[27px] h-[27px] mt-[5px]"></img>
            <h3 className="refer_line ml-[20px]">LeaderBoard</h3></div>
            <hr className="line"></hr>
            <img src="bar.png" className="leaderboard_image"></img>
            <div className="first_part text-[21px] flex relative mb-[100px] justify-center items-center">
                {filteredArray.length > 0 && (
                    <div className="absolute flex-wrap left-[180px] w-[150px] leading-7 justify-center items-center flex">
                        {filteredArray[0].Name}
                    </div>
                )}
                {filteredArray.length > 1 && (
                    <div className="absolute left flex-wrap left-[60px] w-[100px] leading-7 justify-center items-center flex">
                        {filteredArray[1].Name}
                    </div>
                )}
                {filteredArray.length > 2 && (
                    <div className="absolute flex-wrap left-[320px] w-[130px] leading-7 justify-center items-center flex">
                        {filteredArray[2].Name}
                    </div>
                )}
                <hr></hr>
            </div>
            <div className="second_part">
                <ParticipantTable participants={showAllParticipants ? top15Participants : top5Participants} />
                <div className="leaderboard-button">
                    {!showAllParticipants ? (
                        <button onClick={() => setShowAllParticipants(true)}>
                            View Leaderboard
                        </button>
                    ) : (
                        <button onClick={() => setShowAllParticipants(false)}>
                            Hide
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Leaderboard;