import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    leaderboard: [],
}

export const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        adduser: (state,action) => {
            state.user = action.payload
        },
        addleaderboard: (state,action) => {
            state.leaderboard = action.payload
        }
    },
})

export const {adduser} = userSlice.actions;
export const {addleaderboard} = userSlice.actions;

export default userSlice.reducer