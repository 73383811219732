import React, { useState, useEffect } from "react";
import { Client, Databases } from "appwrite";
import { Link } from "react-router-dom";

const Tasks = () => {
  const [showAllTask, setShowAllTask] = useState(false);
  const [taskData, setTaskData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const client = new Client();

      try {
        if (client) {
          client
            .setEndpoint("https://cloud.appwrite.io/v1")
            .setProject("657728939a212e1ff668");
        } else {
          console.error('Error: Appwrite client is not defined.');
        }

        const databases = new Databases(client);

        const response = await databases.listDocuments(
          "657731ac6ecfadf05ab3",
          "657731ee9ad6519bc635"
        );
        // console.log(response.documents);
        setTaskData(response.documents);
      } catch (error) {
        console.error('Error during data fetch:', error); // Failure
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {taskData.slice(0, showAllTask ? undefined : 7).map((task) => (
        <Link to={task.Link} target="_blank">
          <div className="relative flex bg-[#DBDBDB21] mb-[10px] h-[107px] rounded-[6px] mt-[20px] pl-[46px] pt-[26px] pb-[28px]" key={task.$id}>
            <div className="Task">
              <h3 className="font-[Montserrat] font-bold text-[20px]">Day-{task.Day}</h3>
              <h4 className="font-[Montserrat] text-[16px] pt-[5px]">{task.Title}</h4>
              {task.Link ? (
                <img src="Vector1.png" className="absolute w-[16px] h-[12px] top-[37px] right-[45px]" ></img>
              ) : (
                <img src="Vector.png" className="absolute w-[16px] h-[20px] top-[37px] right-[45px]" ></img>
              )}
            </div>
          </div>
        </Link>
      ))}
      <div className="flex justify-center mt-[20px] color-[#3273F6]">
        {!showAllTask ? (
          <button onClick={() => setShowAllTask(true)}>View More</button>
        ) : (
          <button onClick={() => setShowAllTask(false)}>Hide Task</button>
        )}
      </div>
    </div>
  );
};

export default Tasks;
