import React from 'react';

function Refer() {

  return (
    <div>
      <p className='refer_line'>Share with your Friends</p>
      <hr className='line'></hr>
      <div className='outertexttocopy'>
        <div id="textToCopy" className='texttocopy'>
          https://vcode.csivitap.co.in/
        </div>
      </div>
    </div>
  );
}

export default Refer;